import { AxiosResponse } from "axios";
import { IResponseJSON } from "@/conection/interface";
import { IPauseCall, IReasonList } from "@/store/modules/Pause";
import { IOmniBody } from "@/store/modules/History";
import { iHistoryResponse } from "@/callcenter/omni";
import { dynamicForm } from "./consts";
import { env } from "@/config";
import { DateTime } from "ts-luxon";
import { IConfig, IndexType } from "@/types/store";
import { IColumn } from "@/types/components";

export function parseResponse(response: AxiosResponse) {
	const res: IResponseJSON = {
		status: "Error",
		message: "El servidor respondio con error",
	};
	res.message = response.data.request_message;
	res.status = response.data.request_status;
	if (response.data.request_error_code)
		res.errorCode = response.data.request_error_code;
	return res;
}

export function parseChatPauseList(ListPausas: IPauseCall[]): IReasonList[] {
	const reasonsPaused: IReasonList[] = [];

	ListPausas.forEach(e => {
		reasonsPaused.push({
			active: true,
			createdAt: "",
			descripcion: e.title,
			organization: "",
			razon: e.title,
			updatedAt: "",
			__v: 0,
			_id: e.value,
		});
	});
	return reasonsPaused;
}

export async function formatHistoryData(resultado: any, dynamicUser?: IConfig) {
	const TD: IOmniBody[] = [];
	const th: IColumn[] = [
		{ text: "Tipo", value: "Tipo", width: 130 },
		{ text: "Cola", value: "queue", width: 130 },
		{ text: "Fecha/Hora", value: "FechaHora", width: 200 },
		{ text: "Cliente", value: "Cliente", width: 200 },
		{ text: "Número", value: "Numero", width: 155 },
		{ text: "Agente", value: "Agente", width: 200 },
		{ text: "Tipificacíon", value: "Tipificación", width: 210 },
		{ text: "Subtipificacion", value: "SubTipificación", width: 200 },
		{ text: "Comentario", value: "Comentario" },
	];

	if (dynamicUser) {
		dynamicUser.keys.forEach(key => {
			const field = dynamicUser.data.find(field => field.name === key);
			th.splice(4, 0, {
				text: field?.label ?? "",
				value: field?.name ?? "",
				default: "Sin dato provisto",
				formatter(row, column) {
					if (!row[column.property]) return "Dato no provisto";
					if (typeof row[column.property] === "object")
						return row[column.property].label;
					return row[column.property];
				},
			});
		});
	}
	resultado.forEach((element: iHistoryResponse) => {
		const date = DateTime.fromISO(element.createdAt).toFormat(
			"dd/MM/yyyy hh:mm a"
		);
		const agent = element.agent?.name ?? element.agent?.auth?.username;
		const client = element?.client?.customer?.full_name
			? element?.client?.customer?.full_name
			: element?.client?.customer?.name;

		TD.push({
			Tipo: element.callType ?? "Dato no provisto",
			FechaHora: date,
			Agente: `${agent} [${element.agent?.auth?.code}]`,
			Cliente: client ?? "Desconocido",
			Numero: element.phone_number,
			queue: element?.queue ?? "N/A",
			Tipificación: element.tipificacion?.tipification ?? "Dato no provisto",
			SubTipificación:
				element.subtipificacion?.subtipificacion ?? "Dato no provisto",
			Comentario: element.comment ?? "",
			Code: element.agent?.auth?.code,
			_id: element._id,
			...(element.client?.dynamicData ?? {}),
		});
	});
	return { TD, th };
}
export async function formatDynamicHistoryData(
	resultado: any,
	userHeaders?: IConfig,
	formHeaders?: IConfig
) {
	const TD: IOmniBody[] = [];
	const th = await getTh(userHeaders, formHeaders);

	resultado.forEach((element: iHistoryResponse) => {
		const agent = element.agent?.name ?? element.agent?.auth?.username;
		const client = element?.client?.customer?.full_name
			? element?.client?.customer?.full_name
			: element?.client?.customer?.name;
		const data: IndexType<any> = {
			FechaHora: DateTime.fromISO(element.createdAt).toFormat(
				"dd/MM/yyyy hh:mm a"
			),
			Agente: `${agent} [${element.agent?.auth?.code}]`,
			Cliente: client ?? "Desconocido",
			Numero: element.phone_number,
			Tipo: element.callType ?? "Dato no provisto",
			queue: element?.queue ?? "N/A",
			id: element.qualificationCallCode,
			_id: element._id,
		};
		element.agent_group && (data.agent_group = element.agent_group);
		Object.keys(element.client?.dynamicData ?? {}).forEach(key => {
			data[`user_${key}`] =
				element.client?.dynamicData?.[key] ?? "Dato no provisto";
		}),
			TD.push(
				Object.assign(data, element.data, {
					rawData: element.data2,
				}) as any
			);
	});

	return { TD, th };
}
export async function getTh(
	dynamicUser?: IConfig,
	configDynamicForm?: IConfig
): Promise<IColumn[]> {
	const th: IColumn[] = [
		{ text: "Tipo", value: "Tipo", width: 120 },
		{ text: "Cola", value: "queue", width: 130 },
		{ text: "Fecha/Hora", value: "FechaHora", width: 200 },
		{ text: "Cliente", value: "Cliente", width: 200 },
		{ text: "Número", value: "Numero", width: 155 },
		{ text: "Agente", value: "Agente", width: 200 },
	];
	if (dynamicUser) {
		dynamicUser.keys.forEach(key => {
			const field = dynamicUser.data.find(field => field.name === key);
			th.splice(4, 0, {
				text: field?.label ?? "",
				value: `user_${field?.name ?? ""}`,
				default: "Sin dato provisto",
				formatter(row, column) {
					if (!row[column.property]) return "Dato no provisto";
					if (typeof row[column.property] === "object")
						return row[column.property].label;
					return row[column.property];
				},
			});
		});
	}
	if (!dynamicForm) return th;
	const dataForm = configDynamicForm?.data ?? (await dynamicForm);
	dataForm.forEach(element => {
		th.push({
			value: element.name,
			text: element.label,
		});
	});
	return th;
}
export async function getFields(formHeaders?: IConfig): Promise<string> {
	let returnValue = "";
	// return returnValue;
	if (!env.DynamicTypification) return returnValue;
	const dynamic = formHeaders?.data ?? (await dynamicForm);
	if (dynamic == undefined) return returnValue;
	returnValue =
		dynamic?.reduce((acc, crr, crrIndex) => {
			return crrIndex > 0 ? `${acc},${crr.name}` : crr.name;
		}, "") ?? "";

	return returnValue;
}
